import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

//アコーディオン
$(".header__spMenu__accordion").each(function () {
  $(this).on("click", function () {
    $("+.header__spMenu__accordion__list", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$(
  ".header__spBtn, .header__spMenu__close, .header__spMenu__item, .header__spMenu__accordion__list a"
).on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(".header__spBtn").hasClass("is-active")) {
    $(".header__spBtn").removeClass("is-active");
    $(".header__spMenu").removeClass("is-active");
  } else {
    $(".header__spBtn").addClass("is-active");
    $(".header__spMenu").addClass("is-active");
  }
});

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${pathUrl}/privacy" target="_blank">` +
        $(this).text() +
        `<img src="${pathSrc}/img/common/external-link.svg" alt=""/></a>に同意する`
    );
  });
});

$('a[href^="#"]').click(function () {
  const windowWidth = $(window).width();
  const windowSm = 835;
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  let position = 0;
  if (windowWidth > windowSm) {
    position = target.offset().top - 80;
  } else {
    position = target.offset().top - 0;
  }
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

$(".topFaq__item__q").each(function () {
  $(this).on("click", function () {
    $("+.topFaq__item__a", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});
